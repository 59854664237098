import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="О нас" />

<h1>О центре "УмникУм"</h1>

<p>Центр развития и подготовки школьников "УмникУм" успешно работает более 5 лет.</p>

<p>В нашем центре трудится команда опытных преподавателей и руководитель с большим стажем работы.</p> 

<p>За годы работы мы добились высоких результатов в подготовке школьников к экзаменам ЕГЭ и ОГЭ.</p>

<p>Наши ученики демонстрируют стабильно высокие баллы на экзаменах.</p>

<p>Родители оставляют положительные отзывы о работе центра и педагогов.</p>

<p>"УмникУм" - это высокое качество образования, индивидуальный подход и гарантия отличной подготовки к экзаменам!</p>

	</Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`